// src/mixins/clickMixin.js create global js file for helpers

export default {
    data(){
        return {
            amt:0,
            a : ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '],
b : ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'],

        }
    },
    methods: {
        makeUpperCase(str) {
            if(str != null && str != ''){
                str = str.charAt(0).toUpperCase() + str.slice(1);
            }
            return str;
        },
        
      /*   inWords (num) {
            if ((num = num.toString()).length > 9) return 'overflow';
            var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return; var str = '';
            str += (n[1] != 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ' : '';
            str += (n[2] != 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ' : '';
            str += (n[3] != 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ' : '';
            str += (n[4] != 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) + ' ' : '';
            return str;
        }, */

        inWordscheck(number) {
            if ((number < 0) || (number > 999999999)) 
            { 
                return "NUMBER OUT OF RANGE!";
            }
            var Gn = Math.floor(number / 10000000);  /* Crore */ 
            number -= Gn * 10000000; 
            var kn = Math.floor(number / 100000);     /* lakhs */ 
            number -= kn * 100000; 
            var Hn = Math.floor(number / 1000);      /* thousand */ 
            number -= Hn * 1000; 
            var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
            number = number % 100;               /* Ones */ 
            var tn= Math.floor(number / 10); 
            var one=Math.floor(number % 10); 
            var res = ""; 
        
            if (Gn>0) 
            { 
                res += (this.inWordscheck(Gn) + " Crore"); 
            } 
            if (kn>0) 
            { 
                    res += (((res=="") ? "" : " ") + 
                    this.inWordscheck(kn) + " Lakh"); 
            } 
            if (Hn>0) 
            { 
                res += (((res=="") ? "" : " ") +
                this.inWordscheck(Hn) + " Thousand"); 
            } 
        
            if (Dn) 
            { 
                res += (((res=="") ? "" : " ") + 
                this.inWordscheck(Dn) + " Hundred"); 
            } 
        
        
            var ones = Array("", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen","Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eightteen","Nineteen"); 
        var tens = Array("", "", "Twenty", "Thirty", "Fourty", "Fifty", "Sixty","Seventy", "Eighty", "Ninety"); 
        
            if (tn>0 || one>0) 
            { 
                if (!(res=="")) 
                { 
                    res += " And "; 
                } 
                if (tn < 2) 
                { 
                    res += ones[tn * 10 + one]; 
                } 
                else 
                { 
        
                    res += tens[tn];
                    if (one>0) 
                    { 
                        res += (" " + ones[one]); 
                    } 
                } 
            }
        
            if (res=="")
            { 
                res = "zero"; 
            } 
            return res;
        },
        inWords(value) {
            var fraction = Math.round(this.frac(value)*100);
            var f_text  = "";
        
            if(fraction > 0) {
                f_text = "And "+this.inWordscheck(fraction)+" ";
            }
        
            return 'Rupees '+this.inWordscheck(value)+" "+f_text+" Only";
        },
        
         frac(f) {
            return f % 1;
        },
        dateTimeFormat(datetIme)
        {
          let creationDate=datetIme;
          creationDate=creationDate.split(' ');
          return this.date_format(creationDate[0])+'  '+ this.convert_24_12hour(creationDate[1]);
        },
        date_format(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();

			},
            getDateOrdinal(date) {
                let day = date.getDate();
                if (day > 3 && day < 21) return 'th';
                switch (day % 10) {
                  case 1:  return "st";
                  case 2:  return "nd";
                  case 3:  return "rd";
                  default: return "th";
                }
              },
              goBack() {
                this.$router.go(-1)
              },
              numberWithCommas(x) {
              
                 var num = x ? x : 0;
                     return num.toLocaleString('en-IN')
             },
             convert_24_12hour(timeString)
             {
               const [hourString, minute] = timeString.split(":");
               const hour = +hourString % 24;
               return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
             },
           

    },
   
};