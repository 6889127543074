<template>
    <div>
        <b-row>
            <b-col lg="6" md="6" sm="6" class="heading-title">
                <h4>Vendor details</h4>
                <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
                    <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
                    <b-breadcrumb-item to="/manage_vendors">View Vendors</b-breadcrumb-item>
                    <b-breadcrumb-item active>Vendor Detail</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
            <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
        <br>
        <div class="d-flex justify-content-between mt-3">
			<div>
				<h4> Vendor ID - {{ details.vendor_auto_id }}
					
				</h4>
			</div>
			<div>
				
			</div>
		</div> 
		<b-row class="mt-4" >
			
			
			<b-col md="6">
				<div class="card mb-2  p-2">
				<h6 class="custom-text"><span>Personal Details</span></h6>
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2">First name</b-col>
					<b-col cols="12" md="8" sm="12">
						
						<b-form-group id="f_name"  >
							<b-form-input 
							class="form-control venue-custom-input"  disabled 
							name="" id="" aria-describedby="helpId" placeholder=" first name" :value="details.first_name">
							</b-form-input>
							
						</b-form-group>
					</b-col>
				
				</b-row>
				
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2">Last name</b-col>
					
					<b-col cols="12" md="8"  sm="12">
						<b-form-group id="l_name"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" Last name" :value="details.last_name"
							>
							</b-form-input>
							
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<div class="card mb-2  p-2">
				<h6 class="custom-text mt-4"><span>Address Details</span></h6>
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2">Address</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="address"  >
							<b-form-textarea 
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" Address"
							:value="details.address" >
							</b-form-textarea>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2"> Pincode</b-col>
					
					<b-col cols="12" md="8">
						<b-form-group id="pincode"  >
							<b-form-input 
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" pincode"  :value="details.pincode"
							>
							</b-form-input>
							
						</b-form-group>
					</b-col>
				</b-row>
				
				<b-row class="ml-3 mr-3">
					
					<b-col cols="4" md="4" class="text-left mt-2">City</b-col>
					<b-col cols="12" md="8">
						
						<b-form-group id="city"  >
							<b-form-input 
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" city"  :value="details.city">
							</b-form-input>
							
						</b-form-group>
						
					</b-col>
					
				</b-row> 
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2"> State</b-col>
					
					<b-col cols="12" md="8">
						
						<b-form-group id="state"  >
							<b-form-input 
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" state"
                            :value="details.state">
							</b-form-input>
							
						</b-form-group>
						
					</b-col>
				</b-row>
				
				
			</div>
			</b-col>
		
			<b-col md="6">
				<div class="card mb-2  p-2">
				<h6 class="custom-text "><span>Contact Details</span></h6>
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2">Email address</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="email"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled 
							name="" id="" aria-describedby="helpId" 
							placeholder=" Email Address"  :value="details.email">
							</b-form-input>
							
						</b-form-group>
						
					</b-col>
					
				</b-row>
				<b-row class="ml-3 mr-3">
					<b-col cols="4" md="4" class="text-left mt-2">Phone number</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder=" phone number"  :value="details.phone">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
					
				</b-row>
				</div>

				<div class="card mb-2  p-2">
				
				<h6 class="custom-text mt-4"><span>KYC Details</span></h6>
				<b-row class="ml-3 mr-3">
                    <b-col cols="4" md="4" class="text-left mt-2">GST No.</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="GST No"  :value="details.gst_no">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                    <b-col cols="4" md="4" class="text-left mt-2">PAN No.</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="PAN No"  :value="details.pan_no">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                    <b-col cols="4" md="4" class="text-left mt-2">Details</b-col>
					<b-col cols="12" md="8" class="mt-2">
						
						<b-form-group id="address"  >
							<b-form-textarea 
							class="form-control venue-custom-input" disabled  name="" 
							id="" aria-describedby="helpId" placeholder=" PAN  Details"
							:value="Pan_details">
							</b-form-textarea>
							
						</b-form-group>
						
					</b-col>
                    <b-col cols="4" md="4" class="text-left mt-2">Tan No</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Tan No"  :value="details.tan_no">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                  

					
				</b-row>
				</div>
			</b-col>
           
            <b-col md="6">
				<div class="card mb-2  p-2">
                <h6 class="custom-text mt-4"><span>Bank Details</span></h6>
            <b-row class="ml-3 mr-3">
                    <b-col cols="4" md="4" class="text-left mt-2">Account Number.</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Account Number"  :value="details.account_no">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                    <b-col cols="4" md="4" class="text-left mt-2">Account Name.</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Account Name"  :value="details.account_holder_name">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                   
                    <b-col cols="4" md="4" class="text-left mt-2">Bank Name</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Bank Name"  :value="details.bank_name">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                    <b-col cols="4" md="4" class="text-left mt-2">Branch Name</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Branch Name"  :value="details.branch_name">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                  <b-col cols="4" md="4" class="text-left mt-2">IFSC code</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="IFSC code"  :value="details.ifsc_code">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
                  

					
				</b-row>
				<b-row class="ml-3 mr-3">
                    <b-col cols="4" md="4" class="text-left mt-2">UPI Number</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="UPI_number"  :value="details.UPI_number">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
					


                </b-row>
				</div>
				
            </b-col>
			
            <b-col md="6" class="">
				<div class="card mb-2  p-2 ">
                <h6 class="custom-text mt-4"><span>Admin Management</span></h6>
                <b-row class="ml-3 mr-3">
                    <b-col cols="4" md="4" class="text-left mt-2">Created By</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="Created by"  :value="details.name">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
					<!-- <b-col cols="4" md="4" class="text-left">Auto VB Invoice Generate</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-checkbox v-model="auto_gene" name="check-button" switch @change="vbautogenerate">
							</b-form-checkbox>
							
							
						</b-form-group> 
					</b-col> -->

                    <b-col cols="4" md="4" class="text-left ">Offline</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-checkbox v-model="offline" name="check-button" switch>
							</b-form-checkbox>
							
						</b-form-group> 
					</b-col>
                   
                    <b-col cols="4" md="4" class="text-left ">Online</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-checkbox v-model="online" name="check-button" switch>
							</b-form-checkbox>
							
							
						</b-form-group> 
					</b-col>
					<b-col cols="4" md="4" class="text-left ">Date Of Join</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text" readonly
							class="form-control venue-custom-input" 
							
							name="" id=""  :value="dateTimeFormat(details.created_at)">
							</b-form-input>
							
							
						</b-form-group>
					</b-col>
					<b-col cols="4" md="4" class="text-left ">Billing Date</b-col>
					<b-col cols="12" md="6">
						<b-form-group id="phone"  >
							<b-form-input  type="date"
							class="form-control venue-custom-input" 
							
							name="" id="" aria-describedby="helpId" placeholder="Created by"  v-model="billing_date">
							</b-form-input>
							
							
						</b-form-group> 
					</b-col>
					<b-col cols="12" md="2">
						<b-button variant="success" class="cursor-pointer" @click="update_billing_date()">Update</b-button>
					</b-col>
					

                </b-row>
				<br>
				<br>
				<br>
				<br>
				</div>
                </b-col>

						<b-col md="9">
				<div class="card mb-2  p-2 ">
                <h6 class="custom-text mt-3"><span>Billing</span></h6>
                <b-row class="ml-3 mr-3 mb-4">
					<b-col cols="6" md="6" class="text-left border-right">
						<b-row class="ml-3 mr-3 mb-4">
					
					<b-col cols="12" md="8">
						<div class="d-flex">
							<div >
								Manual  &nbsp;
							</div>
							<div>
								<b-form-group id="phone"  >
							<b-form-checkbox v-model="biling_mode" name="check-button" switch  @change="Billing_date_check">
							</b-form-checkbox> 
							
							
						</b-form-group> 
							</div>
							<div>
								Auto
							</div>
							
						</div>
					
					</b-col>
					</b-row>

					<b-row class="ml-3 mr-3" >
					<b-col cols="5" md="5" class="text-left">
						<b-form-input  type="date"
							class="form-control venue-custom-input" 
							
							name="" id="" aria-describedby="helpId" v-model="auto_date" :min="auto_date" :max="auto_date" >
							</b-form-input>
					</b-col>
					<b-col cols="5" md="5">
						<b-form-input  type="date"
							class="form-control venue-custom-input" 
							
							name="" id="" aria-describedby="helpId" placeholder="IFSC code" :min="auto_date" :max="end_max_date" v-model="end_date" :readonly="biling_mode==true" @change="percentage_click">
							</b-form-input>
					</b-col>
					<b-col cols="2" md="2" v-if="biling_mode==false">
						<b-button variant="success" class="cursor-pointer" @click="manual_bill_generate">Generate</b-button>
					</b-col>

				</b-row>

					</b-col>
					<b-col cols="3" md="3" class="text-left">
						<b-row class="ml-3 mr-3 mb-4">
						<!-- <b-col cols="4" md="4" class="text-left"> </b-col> -->
						<b-col cols="12" md="8">
							<div class="d-flex">
								<div class="mr-2">
									Percentage  &nbsp;  &nbsp;
								</div>
								<div >
									<b-form-group id="phone"  >
								<b-form-checkbox v-model="biling_status" name="check-button" switch  @change="percentage_click" >
								</b-form-checkbox> 
								
								
							</b-form-group> 
								</div>
								<div>
									Subscription
								</div>
								
							</div>
						
						</b-col>
						</b-row>
						<b-row class="ml-3 mr-3 mb-4">
						<!-- <b-col cols="4" md="4" class="text-left"> </b-col> -->
						<b-col cols="6" md="6">
						<b-form-input  type="text"
							class="form-control venue-custom-input" 
							
							name="" id="" aria-describedby="helpId" placeholder="Enter Subscription Amount" v-model="subs_amount" >
							</b-form-input>
					</b-col>
					<b-col cols="6" md="6">
+18% GST
					</b-col>
					
					
					</b-row>
					
					

					</b-col>
					<b-col cols="3" md="3">
						<i :class="{['mdi']:true,[show_table ? 'mdi-eye':'mdi-eye-off']:true}" class="cursor-pointer" @click="show_all_table"></i>
							<table class="table tableNew" v-if="!biling_status && show_table">
								
								<tr v-for="(ranges,r) in vb_pencentage_list" :key="r">
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_from)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_to))}} </td>
									<td>{{ ranges.percentage }}%</td>
								</tr>
							</table>
							<table class="table tableNew" v-else-if="biling_status && show_table">
								
								<tr >
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(0)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(99999))}} </td>
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(25000))}}</td>
								</tr>
								<tr >
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(100000)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(300000))}} </td>
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(35000))}}</td>
								</tr>
							</table>
						</b-col>
				</b-row>
				
					
					

					
			
</div>
</b-col>
<b-col md="3" >
	<b-card class=" w-100 mb-2">
		<div class="d-flex justify-content-between ">
			<span>Activted Package</span> <b-button  variant="primary" size="sm" @click="Credit_wallet" >Cancel Package</b-button>
		</div>
		<span class="text-success">{{details.billing_type==1 ? 'Suscription' : 'Monthly Slabs'}}</span>
		&nbsp; &nbsp; <span class="text-danger">Contruct Valid Upto {{ details.billing_type==0 ? 'Life time' : date_format(details.expire_date) }} </span>

		
	</b-card>
	<b-card class=" w-100" :class="{[biling_status ? 'disabledcard':'']:true}">
		<span>Wallets</span>
		<br>
              <div class="d-flex justify-content-between ">
				<div class="">
                 <div>
                <i class="mdi mdi-wallet-travel" style="    font-size: 50px;color: green;"></i>
                 </div>
                </div>
                <div class="mr-2">
                 
                  <h2><i class="mdi mdi-currency-inr"></i>{{  numberWithCommas(details.wallet_amount) }}</h2>
                </div>
             
            </div>
			<div class="d-flex align-items-center mb-2">
				<b-button  variant="primary" size="sm" @click="Credit_wallet" >Credit Wallet</b-button>
				<!-- <b-button variant="success" size="sm">Debit Wallet</b-button> -->
			</div>
            </b-card>
			<b-modal id="modal-sm-wallet" size="sm" title="Wallet " ref="my-modal" 

      @ok="handleOk" centered class="wallet-small" ok-title="Credit " >
	
				<form ref="form" @submit.stop.prevent="handleSubmit">
					<b-form-group
					label="Wallet Amount"
					label-for="name-input"
					invalid-feedback="Amount is required"
					>
					<b-form-input
						id="name-input"
						v-model="wallet_amounts"
						required
						type="number"
						placeholder="Enter Wallet Amount"
					></b-form-input>
					</b-form-group>

					<b-form-checkbox
						id="checkbox-1"
						v-model="monthly_status"
						name="checkbox-1"
						>
						Recurring payment
					</b-form-checkbox>
				</form>
			</b-modal>
</b-col>

<b-col md="12">
<div class="card mb-2  p-2">
                <h6 class="custom-text mt-4"><span>Billing Transcation</span></h6>


				<table class="table table-bordered mt-5">
  <thead>
    <tr>
      <th scope="col">Billing Timeline</th>
	<th scope="col">Date</th>
      <th scope="col">Invoice Number</th>
      <th scope="col">Booking Details</th>
      <th scope="col" class="text-center">VB % </th>
      
      <th scope="col">Amount</th>
      <th scope="col">TDS Amount</th>
      <th scope="col">Payable Amount</th>
      <th scope="col">Credit Note</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(vb,i) in vendor_payment" :key="i">
    <th scope="row">{{ date_format(vb.inv_from) }} - {{ date_format(vb.inv_to) }}</th>
	<td>{{ date_format(vb.vb_month) }}</td>
      <td>
		<router-link :to="'/Finance/vb/invoice/' +  vb.id" >
							{{ vb.invoice_id }}
						</router-link>
		</td>
		<td>
			<!-- Total Booking : {{ vb.booking_count }} <br> -->
			Event Booking : {{ vb.event_count }}  <br> 
			Total VB Invoice  :  {{ vb.invoice_count }} <br>
		</td>
     
      <td class="text-center cursor-pointer" ><span :id="'tooltip-targets-'+i">{{ vb.Vbpercentage }}%</span>
	
		<b-tooltip :target="'tooltip-targets-'+i" triggers="hover" placement="right" no-fade>
			<div>
<table class="table table-fonts-color">
	<tr>
		<td>Range</td>
		<td>Percentage</td>
	</tr>
	<tr v-for="(ranges,r) in vb_pencentage_list" :key="r">
		<td>{{ ranges.range_from }} - {{ ranges.range_to }}</td>
		<td>{{ ranges.percentage }}%</td>
	</tr>
</table>
			</div>
		</b-tooltip>
	
	</td>
      <td class="text-right"><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(vb.withouttax) }}</td>
      <td class="text-right"><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(vb.tds) }}</td>
      <td class="text-right"><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(vb.withouttax-vb.tds) }}</td>
      <td class="text-right"><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(vb.credit_amt) }}</td>
    </tr>
   
  </tbody>
</table>
	
				</div>
			</b-col>
			<b-col md="12">
				<b-card class="mb-4 w-100">
					<h6 class="custom-text mt-4"><span>Reset Password</span></h6>
                    <b-form @submit.prevent="handleSubmit">
                       
                        <b-form-group id="input-group-1" label="New Password:" label-for="input-1">
                            <b-form-input v-model="$v.form.new_password.$model" :state="validateState('new_password')"
                                type="password" placeholder="Enter new password"></b-form-input>
                            <b-form-invalid-feedback>
                                <span v-if="!this.$v.form.new_password.required">
                                    Please enter new password</span>
                                <span v-if="this.$v.form.new_password.required &&
                                    !this.$v.form.new_password.minLength
                                    ">Password must be 8 characters long.</span>
                                <span v-if="this.$v.form.new_password.required &&
                                    this.$v.form.new_password.minLength &&
                                    !this.$v.form.new_password.passwordCheck
                                    ">Password should contain at least a-z or A-Z and number and
                                    special character..</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-1" label="Confirm New Password:" label-for="input-1">
                            <b-form-input v-model="$v.form.confirm_password.$model"
                                :state="validateState('confirm_password')" type="password"
                                placeholder="Confirm new password"></b-form-input>
                            <b-form-invalid-feedback>
                                <span v-if="!this.$v.form.confirm_password.required">Please confirm new password.
                                </span>
                                <span v-if="this.$v.form.confirm_password.required &&
                                    !this.$v.form.confirm_password.sameAsPassword
                                    ">The confirm password must be same as password.
                                </span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-button type="submit" variant="primary" @click="handleSubmits">Change</b-button>
                    </b-form>
                </b-card>
				</b-col>
			<!-- 	<b-col md="6">
				<div class="card mb-2  p-2">
                <h6 class="custom-text mt-4"><span>UPI </span></h6>
                <b-row class="ml-3 mr-3">
                    <b-col cols="4" md="4" class="text-left mt-2">UPI Number</b-col>
					<b-col cols="12" md="8">
						<b-form-group id="phone"  >
							<b-form-input  type="text"
							class="form-control venue-custom-input" disabled
							
							name="" id="" aria-describedby="helpId" placeholder="UPI_number"  :value="details.UPI_number">
							</b-form-input>
							
						</b-form-group> 
					</b-col>
					


                </b-row>
				</div>
                </b-col> -->
		</b-row>
		
		
		
		
		
	</div>
</template>
<script>
	// -----------------------------------------
	// Import Components Here
	// -----------------------------------------
	
	
	// -----------------------------------------
	// Export Here
	// -----------------------------------------
	import axios from "axios";
import {
    required, sameAs, minLength, helpers
} from "vuelidate/lib/validators";
const passwordCheck = helpers.regex(
    "passwordCheck",
    /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@&$#%]).*$/
);
import { validationMixin } from "vuelidate";
	import helperss from "../../mixins/helpers";
	export default {
		name: "vendorDetail",
		mixins: [helperss,validationMixin],
		data: () => ({
			title: "vendorDetail",
			// Month Table
			details:[],
			vendor_payment:[],
			vb_pencentage_list:[],
			offline:true,
			online:true,
			Pan_details:'',
			billing_date:'',
			auto_gene:true,
			biling_mode:'',
			auto_date:'',
			end_date:'',
			wallet_amounts: '',
			monthly_status: false,
			biling_status: false,
			subs_amount: 0,
			show_table: true,
			form: {
				email: '',
				new_password: '',
				confirm_password: '',
			},
			
		}),
		validations: {
        form: {
            new_password: { required, minLength: minLength(8), passwordCheck },
            confirm_password: { required, sameAsPassword: sameAs("new_password") },
        },
    },
		methods: {
			handleSubmits() {
            this.$v.form.$touch();
            if (!this.$v.form.$anyError) {
                this.changePassword();
            }
        },
		changePassword() {
            axios
                .post("/admin/admin_change_password", {
                    email: this.details.email,
                    password: this.form.new_password,
                })
                .then((resp) => {
                    if (resp.data.status) {
                        this.$root.$refs.app.showToast("success", resp.data.message);
                        this.$router.push("/login");
                    } else {
                        this.$root.$refs.app.showToast("danger", resp.data.message);
                    }
                });
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
			show_all_table()
			{
this.show_table = !this.show_table;
			},
			vendorDetails() {
				// this.load = true;
				this.vendors = [];
				axios
				.post("/admin/view_vendor", {
					vendor_id:  this.$route.params.id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						this.details = [];
						if (resp.data.status) {
							this.details = resp.data.vendor_details;
							this.vb_pencentage_list= resp.data.vb_pencentage_list;

							this.billing_date=this.details.free_month;
							this.vendor_payment= resp.data.vendor_payment;
							this.auto_date= resp.data.next_pay_start_date;
							this.end_max_date= resp.data.next_pay_end_date;
							this.end_date= resp.data.next_pay_end_date;
							this.wallet_amounts= this.details.wallet_amount;
						
							this.biling_mode= this.details.auto_billing==1 ? true :false;
							this.biling_status= this.details.billing_type==1 ? true :false;
							this.subs_amount= this.details.subs_amount;
this.percentage_click();
							var Pan_details ;
							Pan_details = this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).legal_name : '' +
							this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).pincode : '' +
							this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).city : '' +
							this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).state : ''  +
							this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).address : '' + 
							this.details.gst_pan_details ? JSON.parse(this.details.gst_pan_details).country : '' ; 
							this.Pan_details=Pan_details;
							

							
							} else {
							this.$router.push('/manage_vendors');
						}
					}
					
				});
				this.show = false;
				this.load = false;
			},
			dateTimeFormat(datetIme)
        {
          let creationDate=datetIme;
          creationDate=creationDate.split(' ');
          return this.date_format(creationDate[0]);
        },
		date_format(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();

			},
            getDateOrdinal(date) {
                let day = date.getDate();
                if (day > 3 && day < 21) return 'th';
                switch (day % 10) {
                  case 1:  return "st";
                  case 2:  return "nd";
                  case 3:  return "rd";
                  default: return "th";
                }
              },
			convert_24_12hour(timeString)
             {
               const [hourString, minute] = timeString.split(":");
               const hour = +hourString % 24;
               return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
             },
			update_billing_date()
			{
				this.$swal({
					title: 'Are you sure to update Billing date?',
					text: "Please  confirm !",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
					}).then((result) => {
					if (result.value) {
						
						axios.post("/admin/update_billing_date",
						{
							billing_date:this.billing_date,
							vendor_id:  this.$route.params.id,
						})
						.then((resp) => {
							resp
							this.$root.$refs.app.showToast(
							"success",
							"Billing Date Updated" 
							);
							this.vendorDetails();
						}); 
					}
				}); 
			},
			vbautogenerate()
			{
				axios.post("/admin/update_billing_generate",
				{
					auto_active:this.biling_mode,
					vendor_id:  this.$route.params.id,
				})
				.then((resp) => {
					resp
					this.$root.$refs.app.showToast(
					"success",
					"Auto Billing Updated" 
					);
					this.vendorDetails();
				}); 
			},
			Billing_date_check()
			{
				this.vbautogenerate();
			/* 	if(this.biling_mode==true)
				{
				this.end_date = "2024-01-31";
				}
				else
				{
					this.end_date = "";
				} */
				

			},
			manual_bill_generate()
			{
				axios.post("/admin/manual_billing_generate",
				{
					vendor_id:  this.$route.params.id,
					from:  this.auto_date,
					to:  this.end_date,
				})
				.then((resp) => {
					resp
					this.$root.$refs.app.showToast(
					"success",
					"Manual Billing Generated" 
					);
					this.end_date="";
					this.vendorDetails();
				}); 
			},
			checkFormValidity() {
				const valid = this.$refs.form.checkValidity()
				return valid
			},
			resetModal() {
				this.wallet_amounts = ''
			},
			handleOk(bvModalEvent) {
				// Prevent modal from closing
				bvModalEvent.preventDefault()
				// Trigger submit handler
				this.handleSubmit()
			},
			handleSubmit() {
				// Exit when the form isn't valid
				if (!this.checkFormValidity()) {
				return
				}

				axios.post("/admin/save_wallet_amount",
				{
					vendor_id:  this.$route.params.id,
					Wallet_amount:  this.wallet_amounts,
					monthly_status:  this.monthly_status,
				})
				.then((resp) => {
					resp
					this.$root.$refs.app.showToast(
					"success",
					"Wallet Amount Credited" 
					);
					this.wallet_amounts="";
					this.monthly_status="";
					this.vendorDetails();
					this.$refs['my-modal'].hide();
					
				}); 
				
			},
			Credit_wallet()
			{
				this.wallet_amounts= this.details.wallet_amount;
				this.monthly_status= this.details.wallet_recurring == 1 ? true :false ;
				this.$refs['my-modal'].show();
			},
			isNumeric: function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },
  percentage_click()
  {
	//this.Billing_date_check();
	//alert(this.biling_status)
	axios.post("/admin/percentage_subs_add",
	{
		vendor_id:  this.$route.params.id,
		from:  this.auto_date,
		to:  this.end_date,
		biling_status:  this.biling_status,
	})
	.then((resp) => {
		
		this.subs_amount = this.biling_status ? 'Rs. '+resp.data : resp.data+'%';
		
	}); 
  }
		},
		components: {},
		mounted() {
			this.show = true;
			this.vendorDetails();
			//this.Billing_date_check();
		},
	};
</script>
<style>
    #cancle_sub_btn {
	margin-left: auto;
	display: block;
	}
	#cancel_sub {
	padding-top: 25px;
	width: 100%;
	}
	.cursor-pointer
	{
		cursor:pointer;
	}
	#modal-sm-wallet
	{
		left:35%;
		top:7%;
	}
	.table-fonts-color
	{
color:white !important;
font-size:12px !important;
	}
	.disabledcard
	{
		pointer-events: none;
		background-color:gray;
	}
	.tableNew
	{
		font-size:10px;
	}
	.tableNew td {
		padding: 0.3rem !important;
	}
	.custom-control-input:not(:checked) ~ .custom-control-label::before
	{
		color: #fff !important;
    border-color: #2962ff;
    background-color: #2962ff;
	}
	.custom-control-label::after
	{
		color: #fff !important;
	}
</style>